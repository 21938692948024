import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../../style/AlfDetails.css'; // Assuming custom styles in this file

const AlfDetails = () => {
  const { alfId } = useParams(); //Retrieve the alfId from the URL
  const [alfDetails, setAlfDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAlfDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL}/api/alfData/${alfId}`);
        setAlfDetails(response.data); 
      } catch (error) {
        console.error('Error fetching ALF details:', error);
      } 
      setLoading(false);
    };

    fetchAlfDetails();
  }, [alfId]);

  const handleDownload = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/alfDocuments/${alfId}`, {
        responseType: 'blob', // Ensure proper handling of binary data
      });

      // Create a URL for the blob data and trigger a download
      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `alf_documents_${alfId}.pdf`; // You can dynamically set the filename
      link.click();
    } catch (error) {
      console.error('Error downloading document:', error);
    }
  };

  const handleEdit = () => {
    // Redirect to the edit page or show an edit form
    console.log('Edit button clicked');
    // Example: Navigate to an edit page
    // window.location.href = `/edit/${alfId}`;
  };

  if (loading) return <p>Loading...</p>;
  if (!alfDetails) return <p>No details available</p>;

  return (
    <div className="alf-details-container">
      <section className="content">
        <div className="box box-primary alf-box">
          <div className="box-header with-border">
            <h3 className="box-title">Basic Information</h3>
          </div>
          <div className="box-header with-border">
            <button className="btn btn-primary edit-button" onClick={handleEdit}>
              Edit
            </button>
          </div>
          <div className="box-body">
            <div className="alf-info">
              <p><strong>Name:</strong> {alfDetails.alf_name}</p>
              <p><strong>Address:</strong> {alfDetails.address}</p>
              <p><strong>Contact Person:</strong> {alfDetails.contact_person}</p>
              <p><strong>Email:</strong> {alfDetails.email}</p>
              <p><strong>Phone Number:</strong> {alfDetails.phone_number}</p>
              <p><strong>Website:</strong> {alfDetails.website_url}</p>
              <p><strong>Documents:</strong>
              <button className="btn btn-danger" 
                disabled
                onClick={handleDownload}>
                Download
              </button></p>
              <p><strong>Type of Facility:</strong> {alfDetails.type_of_facility}</p>
            </div>
          </div>
        </div>

        <div className="box box-info alf-box">
          <div className="box-header with-border">
            <h3 className="box-title">Facility Details</h3>
          </div>
          <div className="box-body">
            <div className="alf-info">
              <p><strong>Year Established:</strong> {alfDetails.year_established}</p>
              <p><strong>Total Number of Residents:</strong> {alfDetails.total_residents}</p>
              <p><strong>Total Number of Beds:</strong> {alfDetails.total_beds}</p>
              <p><strong>Specialization:</strong> {alfDetails.specialization}</p>
            </div>
          </div>
        </div>

        <div className="box box-warning alf-box">
          <div className="box-header with-border">
            <h3 className="box-title">Services Offered</h3>
          </div>
          <div className="box-body">
            <div className="alf-info">
              <p><strong>Level of Care Provided:</strong> {alfDetails.care_level}</p>
              <p><strong>24 Hour Medical Care Availability:</strong> {alfDetails.medical_care ? 'Yes' : 'No'}</p>
              <p><strong>Medication Management:</strong> {alfDetails.medication_management ? 'Yes' : 'No'}</p>
              <p><strong>Activities & Programs:</strong> {alfDetails.activities ? 'Yes' : 'No'}</p>
              <p><strong>Dining Options:</strong> {alfDetails.dining_options}</p>
            </div>
          </div>
        </div>

        <div className="box box-danger alf-box">
          <div className="box-header with-border">
            <h3 className="box-title">Dementia/Memory Care Specifics</h3>
          </div>
          <div className="box-body">
            <div className="alf-info">
              <p><strong>Dedicated Memory Care Unit:</strong> {alfDetails.memory_care_unit ? 'Yes' : 'No'}</p>
              <p><strong>Security Features:</strong> {alfDetails.security_features}</p>
              <p><strong>Specialized Staff Training for Dementia Care:</strong> {alfDetails.staff_training ? 'Yes' : 'No'}</p>
              <p><strong>Behavioral Management Programs:</strong> {alfDetails.behavioral_management ? 'Yes' : 'No'}</p>
            </div>
          </div>
        </div>

        {/* Bottom buttons */}
        <div className="box-footer alf-footer">
          <div className="btn-group btn-group-justified">
            <a href="#" className="btn btn-danger">Patients</a> 
            <a href="#" className="btn btn-danger">Caregivers</a>
            <a href="#" className="btn btn-danger">Doctors</a>
            <a href="#" className="btn btn-danger">Nurses</a>
            <a href="#" className="btn btn-danger">Activity Taker</a>
            <a href="#" className="btn btn-danger">Next</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AlfDetails;
