// src/components/FetchData.js
import React from 'react';

const FetchData = ({ data, loading }) => {
  if (loading) {
    return <p>Loading...</p>;
  }

  if (!data || data.length === 0) {
    return <p>No data available. Click "Enquiries" to fetch data.</p>;
  }

  return (
    <div className="container">
      <h1>Enquiries</h1>
      <table id="dataTable" className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email Id</th>
            <th>Current Role</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td>{item.current_role}</td>
              <td>
                <button 
                  disabled
                  className="btn btn-danger" 
                  //onClick={() => handleDetails(alf.id)}
                >
                  Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FetchData;
