// src/components/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation

const Sidebar = ({ onLogout, onFetchData, onFetchAlfData }) => {
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a href="/dashboard" className="brand-link">
        <span className="brand-text font-weight-light">DementiaWhiz</span>
      </a>
      <div className="sidebar">
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <li className="nav-item">
              <Link to="/dashboard" className="nav-link">
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>Dashboard</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/fetch-alf-data" className="nav-link" onClick={onFetchAlfData}>
                <i className="nav-icon fas fa-th"></i>
                <p>ALFs</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/fetch-data" className="nav-link" onClick={onFetchData}>
                <i className="nav-icon fas fa-th"></i>
                <p>Enquiries</p>
              </Link>
            </li>            
            <li className="nav-item">
              <button className="btn btn-danger btn-block" onClick={onLogout}>
                <i className="fas fa-sign-out-alt"></i> Logout
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
