import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ALFForm = ({ onSuccess }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    alfName: '',
    address: '',
    contactPerson: '',
    email: '',
    phoneNumber: '',
    websiteUrl: '',
    yearEstablished: '',
    numberOfResidents: '',
    numberOfBeds: '',
    specialization: '',
    typeOfFacility: '',
    careLevel: '',
    medicalCare: false,
    medicationManagement: false,
    activities: false,
    diningOptions: '',
    memoryCareUnit: false,
    securityFeatures: '',
    staffTraining: false,
    behavioralManagement: false,
    personalizedCare: false,
    alfLogo: null,
    documents: null
  });
  const [errors, setErrors] = useState({});

  const basicInfoRef = useRef(null);
  const facilityDetailsRef = useRef(null);
  const servicesOfferedRef = useRef(null);
  const memoryCareRef = useRef(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    setFormData({
      ...formData,
      [name]: e.target.files[0],
    });
  };

  const scrollToSection = (sectionRef) => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setErrors({}); // Reset errors on form submission

  //   const uploadData = new FormData();
  //   Object.keys(formData).forEach((key) => {
  //     uploadData.append(key, formData[key]);
  //   });

  //   try {
  //     const response = await axios.post('http://localhost:5000/api/alf', uploadData, {
  //       headers: { 'Content-Type': 'multipart/form-data' },
  //     });

  //     alert('ALF created successfully');
  //     navigate('/fetch-alf-data');
  //   } catch (error) {
  //     if (error.response && error.response.data && error.response.data.errors) {
  //       const apiErrors = error.response.data.errors;
  //       const errorObj = {};
  //       let firstErrorField = null;

  //       apiErrors.forEach((err) => {
  //         errorObj[err.path] = err.msg;

  //         // Identify the first error field
  //         if (!firstErrorField) {
  //           firstErrorField = err.path;
  //         }
  //       });

  //       setErrors(errorObj);

  //       // Switch to the step that has the first validation error
  //       if (firstErrorField) {
  //         if (firstErrorField === 'alfName' || firstErrorField === 'email') {
  //           setStep(1); // Go to the Basic Information step
  //           scrollToSection(basicInfoRef);
  //         } else if (firstErrorField === 'yearEstablished' || firstErrorField === 'numberOfResidents') {
  //           setStep(2); // Go to the Facility Details step
  //           scrollToSection(facilityDetailsRef);
  //         } else if (firstErrorField === 'medicalCare' || firstErrorField === 'medicationManagement') {
  //           setStep(3); // Go to the Services Offered step
  //           scrollToSection(servicesOfferedRef);
  //         } else if (firstErrorField === 'memoryCareUnit' || firstErrorField === 'securityFeatures') {
  //           setStep(4); // Go to the Memory Care section
  //           scrollToSection(memoryCareRef);
  //         }
  //       }
  //     } else {
  //       console.error('Error submitting form:', error.message);
  //     }
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({}); // Reset errors on form submission

    const uploadData = new FormData();
    Object.keys(formData).forEach((key) => {
      uploadData.append(key, formData[key]);
    });

    try {
      // Submit the form data
      const response = await axios.post(`${process.env.REACT_APP_URL}api/alf`, uploadData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      alert('ALF created successfully');
      if (onSuccess) {
        await onSuccess(); // Ensure the data fetch completes before navigating
      }
      // Navigate to the page with the new ALF data
      navigate(`/fetch-alf-data`);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const apiErrors = error.response.data.errors;
        const errorObj = {};
        let firstErrorField = null;

        apiErrors.forEach((err) => {
          errorObj[err.path] = err.msg;

          // Identify the first error field
          if (!firstErrorField) {
            firstErrorField = err.path;
          }
        });

        setErrors(errorObj);

        // Switch to the step that has the first validation error
        if (firstErrorField) {
          if (firstErrorField === 'alfName' || firstErrorField === 'email') {
            setStep(1); // Go to the Basic Information step
            scrollToSection(basicInfoRef);
          } else if (firstErrorField === 'yearEstablished' || firstErrorField === 'numberOfResidents') {
            setStep(2); // Go to the Facility Details step
            scrollToSection(facilityDetailsRef);
          } else if (firstErrorField === 'medicalCare' || firstErrorField === 'medicationManagement') {
            setStep(3); // Go to the Services Offered step
            scrollToSection(servicesOfferedRef);
          } else if (firstErrorField === 'memoryCareUnit' || firstErrorField === 'securityFeatures') {
            setStep(4); // Go to the Memory Care section
            scrollToSection(memoryCareRef);
          }
        }
      } else {
        console.error('Error submitting form:', error.message);
      }
    }
  };
  
  // Basic Information Section
  const renderBasicInfo = () => (
    <div className="card card-primary" ref={basicInfoRef}>
      <div className="card-header">
        <h3 className="card-title">Basic Information</h3>
      </div>
      <div className="card-body">
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>ALF Name</label>
            <input
              type="text"
              name="alfName"
              className="form-control"
              placeholder="Enter ALF Name"
              value={formData.alfName}
              onChange={handleChange}
              required
            />
            {errors.alfName && <span className="text-danger">{errors.alfName}</span>}
          </div>
          <div className="form-group col-md-6">
            <label>Address</label>
            <input
              type="text"
              name="address"
              className="form-control"
              placeholder="Enter Address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Contact Person</label>
            <input
              type="text"
              name="contactPerson"
              className="form-control"
              placeholder="Enter Contact Person Name"
              value={formData.contactPerson}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label>Email</label>
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Enter Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="text-danger">{errors.email}</span>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Phone Number</label>
            <input
              type="text"
              name="phoneNumber"
              className="form-control"
              placeholder="Enter Phone Number"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label>Website URL</label>
            <input
              type="text"
              name="websiteUrl"
              className="form-control"
              placeholder="Enter Website URL"
              value={formData.websiteUrl}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Type of Facility</label>
            <select
              name="typeOfFacility"
              className="form-control"
              value={formData.typeOfFacility}
              onChange={handleChange}
            >
              <option value="">Select Type of Facility</option>
              <option value="Independent Living">Independent Living</option>
              <option value="Assisted Living">Assisted Living</option>
              <option value="Memory Care">Memory Care</option>
            </select>
          </div>
          <div className="form-group col-md-6">
            <label>ALF Logo</label>
            <input
              type="file"
              name="alfLogo"
              className="form-control"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className="card-footer">
          <button type="button" className="btn btn-secondary" onClick={() => navigate('/fetch-alf-data')}>
            Cancel
          </button>
          <button type="button" className="btn btn-primary ml-2" onClick={() => setStep(2)}>
            Next
          </button>
        </div>
      </div>
    </div>
  );

  // Facility Details Section
  const renderFacilityDetails = () => (
    <div className="card card-primary" ref={facilityDetailsRef}>
      <div className="card-header">
        <h3 className="card-title">Facility Details</h3>
      </div>
      <div className="card-body">
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Year Established</label>
            <input
              type="number"
              name="yearEstablished"
              className="form-control"
              placeholder="Year Established"
              value={formData.yearEstablished}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label>Number of Residents</label>
            <input
              type="number"
              name="numberOfResidents"
              className="form-control"
              placeholder="Number of Residents"
              value={formData.numberOfResidents}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Number of Beds</label>
            <input
              type="number"
              name="numberOfBeds"
              className="form-control"
              placeholder="Number of Beds"
              value={formData.numberOfBeds}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-6">
            <label>Specialization</label>
            <input
              type="text"
              name="specialization"
              className="form-control"
              placeholder="Specialization"
              value={formData.specialization}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Care Level</label>
            <input
              type="text"
              name="careLevel"
              className="form-control"
              placeholder="Care Level"
              value={formData.careLevel}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-6">
            <label>Documents</label>
            <input
              type="file"
              name="documents"
              className="form-control"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className="card-footer">
          <button type="button" className="btn btn-secondary" onClick={() => setStep(1)}>
            Previous
          </button>
          <button type="button" className="btn btn-primary ml-2" onClick={() => setStep(3)}>
            Next
          </button>
        </div>
      </div>
    </div>
  );

  // Services Offered Section
  const renderServicesOffered = () => (
    <div className="card card-primary" ref={servicesOfferedRef}>
      <div className="card-header">
        <h3 className="card-title">Services Offered</h3>
      </div>
      <div className="card-body">
        <div className="form-check">
          <input
            type="checkbox"
            name="medicalCare"
            className="form-check-input"
            checked={formData.medicalCare}
            onChange={handleChange}
          />
          <label className="form-check-label">Medical Care</label>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            name="medicationManagement"
            className="form-check-input"
            checked={formData.medicationManagement}
            onChange={handleChange}
          />
          <label className="form-check-label">Medication Management</label>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            name="activities"
            className="form-check-input"
            checked={formData.activities}
            onChange={handleChange}
          />
          <label className="form-check-label">Activities</label>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            name="diningOptions"
            className="form-check-input"
            checked={formData.diningOptions}
            onChange={handleChange}
          />
          <label className="form-check-label">Dining Options</label>
        </div>
        <div className="card-footer">
          <button type="button" className="btn btn-secondary" onClick={() => setStep(2)}>
            Previous
          </button>
          <button type="button" className="btn btn-primary ml-2" onClick={() => setStep(4)}>
            Next
          </button>
        </div>
      </div>
    </div>
  );

  // Memory Care Section
  const renderMemoryCare = () => (
    <div className="card card-primary" ref={memoryCareRef}>
      <div className="card-header">
        <h3 className="card-title">Memory Care</h3>
      </div>
      <div className="card-body">
        <div className="form-check">
          <input
            type="checkbox"
            name="memoryCareUnit"
            className="form-check-input"
            checked={formData.memoryCareUnit}
            onChange={handleChange}
          />
          <label className="form-check-label">Memory Care Unit</label>
        </div>
        <div className="form-group">
          <label>Security Features</label>
          <input
            type="text"
            name="securityFeatures"
            className="form-control"
            placeholder="Security Features"
            value={formData.securityFeatures}
            onChange={handleChange}
          />
        </div>
        <div className="card-footer">
          <button type="button" className="btn btn-secondary" onClick={() => setStep(3)}>
            Previous
          </button>
          <button type="submit" className="btn btn-primary ml-2">
            Submit
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <form onSubmit={handleSubmit} className="container mt-4">
      {step === 1 && renderBasicInfo()}
      {step === 2 && renderFacilityDetails()}
      {step === 3 && renderServicesOffered()}
      {step === 4 && renderMemoryCare()}
    </form>
  );
};

export default ALFForm;
