import axios from 'axios';
export default class HTTPServices {
  // constructor() {}
  _setInterceptors() {}
  _handleResponse_() {}
  _handleError_() {}
  async getFormData() {
    try {
      // const response = await axios.get('https://dementiawhiz.com/service/api/v1/forms');
      const response = await axios.get('http://localhost:2000/api/v1/forms');
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }



  async postFormData(data) {
    try {
      // const response = await axios.post('https://dementiawhiz.com/service/api/v1/forms',data);
      const response = await axios.post('http://localhost:2000/api/v1/forms',data);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }

  // async FormData(data) {
  //   try {

  //     const response = await axios.get('http://localhost:2000/api/v1/forms',data);
  //     console.log(response);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }
  
  put() {}
  delete() {}
}









