import React from 'react';
import { AppBar, Toolbar, IconButton, Drawer, ListItem, ListItemText,Button } from '@mui/material';
import { NavLink, Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import image from '../images/logo3.png';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Grid,
    Typography,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Stack,
    TextField,
    Snackbar, Alert
  } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import FormControl from "@mui/material/FormControl";
  import InputLabel from "@mui/material/InputLabel";
  import Select from "@mui/material/Select";
  import MenuItem from "@mui/material/MenuItem";
  import FormHelperText from "@mui/material/FormHelperText";
  import axios from 'axios';
  // import ReCAPTCHA from 'react-google-recaptcha';
  import Swal from 'sweetalert2'


export default function Navbar() {
    // let http = new HTTPServices();

    const [mobileOpen, setMobileOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width: 900px)');

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const [open, openchange] = useState(false);
    const functionopenpopup = () => {
      openchange(true);
    };
    const closepopup = () => {
      openchange(false);
    };
    const drawer = (
        <Grid>
            <ListItem button component={NavLink} to="/signin">
                <ListItemText primary="About Us" />
            </ListItem>
            <ListItem button component={NavLink} to="/admin">
                <ListItemText primary="Sample Education" />
            </ListItem>
            <Grid item >
                <Button onClick={functionopenpopup} sx={{color:"green", bgcolor:"black", justifyContent:""}}>JOIN NOW</Button>
            </Grid>

        </Grid>
    );











    // let http = new HTTPServices();


  // const [open, openchange] = useState(false);
  // const functionopenpopup = () => {
  //   openchange(true);
  // };
  // const closepopup = () => {
  //   openchange(false);
  // };

  const [CurrentRole, setCurrentRole] = React.useState("");
  const [CurrentName, setCurrentName] = React.useState("");
  const [CurrentEmail, setCurrentEmail] = React.useState("");
  // const [captchaValue, setCaptchaValue] = useState(null);
  
const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');


  const handleRoleChange = (event) => {
    setCurrentRole(event.target.value);
  };
  const handleNameChange = (event) => {
    setCurrentName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setCurrentEmail(event.target.value);
  };
  // const handleCaptchaChange = (value) =>{
  //    setCaptchaValue(value);
  // };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const submitFormData = async() => {
    // if (!captchaValue) {
    //   alert("Please complete the CAPTCHA");
    //   return;
  // }
  console.log(CurrentName);
  console.log(CurrentEmail);
  console.log(CurrentRole);
     let data = {
      name : CurrentName,
      email : CurrentEmail,
      role : CurrentRole,
      agree : true

    }
    var res = await axios.post(`${process.env.REACT_APP_URL}/api/v1/forms`,data);
    if (res.status === 200){
      // alert("Data saved successfully")
      Swal.fire({
        position: "top-center",
        icon: "success",
        title: "Data saved successfully",
        showConfirmButton: false,
        timer: 3000
      });
      console.log("successful")
       //  setSnackbarMessage('Data saved successfully!');
        // setSnackbarOpen(true);
        closepopup()
    }else{
      setSnackbarMessage('Error saving data!');
         setSnackbarOpen(true);
      console.log("failed")
    }
    
    console.log(res)
  };
    return (
        <Grid container sx={{ bgcolor: '#ffffff' }}>
            <AppBar component="nav" position="static" elevation={0} sx={{ padding: '16px 0', bgcolor: '#ffffff' }}>
                <Toolbar>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Link href="#" style={{ textDecoration: 'none' }}>
                                <img src={image} alt="logo" style={{ width: '58px', height: '59px' }} />
                            </Link>
                            <Typography sx={{ fontSize: '24px', fontWeight: 'bold', color: '#125782', padding: '5px 0 0 15px' }}>
                                DementiaWhiz
                            </Typography>
                        </Grid>
                        {isMobile ? (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerToggle}
                                sx={{ color: '#125782' }}
                            >
                                <MenuIcon />
                            </IconButton>
                        ) : (
                            <Grid item sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <NavLink to="#" style={{ textDecoration: 'none', marginRight: '20px' }}>
                                    <Typography sx={{ fontSize: '19px', fontWeight: '550', fontFamily: 'Poppins', color: '#125782' }}>
                                        About Us
                                    </Typography>
                                </NavLink>
                                <NavLink to="#" style={{ textDecoration: 'none', marginRight: '20px' }}>
                                    <Typography sx={{ fontSize: '19px', fontWeight: '550', fontFamily: 'Poppins', color: '#125782' }}>
                                        Sample Education
                                    </Typography>
                                </NavLink>
                                {/* <NavLink style={{ textDecoration: 'none', marginRight: '20px' }}> */}
                                    <Button onClick={functionopenpopup} sx={{ fontSize: '19px', fontWeight: '600', fontFamily: 'Poppins', color: 'white',bgcolor: '#b31c31',borderRadius: '8px',
              '&:hover': {
                bgcolor: '#b31c31', // Background color on hover
                color: 'white', // Text color on hover
              }, }}>
                                        JOIN NOW
                                    </Button>
                                    <Dialog
            bgcolor="#FBF7E9"
            // fullScreen
            open={open}
            onClose={closepopup}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>
              JOIN NOW{" "}
              <IconButton onClick={closepopup} style={{ float: "right" }}>
                <CloseIcon color="#FFE636"></CloseIcon>
              </IconButton>{" "}
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2} margin={2}>
                <TextField
                  variant="outlined"
                  label="Name"
                  name="Name"
                  value={CurrentName}
                  onChange={handleNameChange}
                ></TextField>
                <TextField
                  variant="outlined"
                  label="Email"
                  name="Email"
                  value={CurrentEmail}
                  onChange={handleEmailChange}
                ></TextField>
                <FormControl required sx={{ m: 2, minWidth: 150 }}>
                  <InputLabel id="demo-simple-select-required-label">
                    Current-Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    name="Currentrole"
                    id="demo-simple-select-required"
                    value={CurrentRole}
                    label="CurrentRole"
                    onChange={handleRoleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Caregiver">Caregiver</MenuItem>
                    <MenuItem value="Family Member"> Family Member </MenuItem>
                    <MenuItem value="Patient">Patient</MenuItem>
                    <MenuItem value="Assisted Living Facility">Assisted Living Facility</MenuItem>
                    <MenuItem value="Activity Taker">Activity Taker</MenuItem>
                  </Select>
                  <FormHelperText>Required</FormHelperText>
                </FormControl>
                <FormControlLabel
                  control={<Checkbox defaultChecked></Checkbox>}
                  label="Agree terms & conditions"
                ></FormControlLabel>
                {/* bgcolor="#125782" */}
                {/* <ReCAPTCHA
                   sitekey="6LdRrDIqAAAAALq0IZG-cJbo8_DcdQX4DEr_VgLI"
                   onChange={handleCaptchaChange}
                                            /> */}
              <Button
                onClick={submitFormData}
                sx={{
                  
                  bgcolor: "#b31c31",
              color: "white",
              '&:hover': {
                bgcolor: '#b31c31', // Background color on hover
                color: 'white', // Text color on hover
              },
                  fontSize: "18px",
                  fontWeight: "700",
                  fontFamily: "poppins",
                }}
              >
                Submit
              </Button>
              </Stack>
            </DialogContent>
            <DialogActions>
              {/* <Button color="success" variant="contained">Yes</Button>
                    <Button onClick={closepopup} color="error" variant="contained">Close</Button>  */}
            </DialogActions>
          </Dialog>
                                {/* </NavLink> */}
                            </Grid>
                        )}
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                anchor="right"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }} // Better open performance on mobile.
                sx={{
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                }}
            >
                {drawer}
            </Drawer>
            <Snackbar
open={snackbarOpen}
autoHideDuration={3000}
onClose={handleCloseSnackbar}
anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
>
<Alert onClose={handleCloseSnackbar} severity={snackbarMessage.includes('Error') ? 'error' : 'success'}>
  {snackbarMessage}
</Alert>
</Snackbar> 
        </Grid>
    );
}
