// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="main-footer">
      <strong>Copyright &copy; 2024 <a href="https://dememtiawhiz.com/">DementiaWhiz</a>.</strong> All rights reserved.
    </footer>
  );
};

export default Footer;
