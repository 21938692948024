import React from "react";
import {
  Grid,
  Typography,
  Link,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import "../style/HomeStyles.css";
import Item from "@mui/material/Box";
// import image1 from "../images/mobile.webp";
// import StarHalfIcon from "@mui/icons-material/StarHalf";
import Header from "./Header";
// import sign1 from "../images/sign1.webp";
// import sign2 from "../images/sign2.webp";
// import sign3 from "../images/sign3.webp";
// import sign4 from "../images/sign4.webp";
// import drink from "../images/drink.webp";
// import drink2 from "../images/drinks-bar.webp";
// import photo1 from "../images/hirshikesh_belani.webp";
// import photo2 from "../images/kate_bonham.webp";
// import photo3 from "../images/nir_eyal.webp";
// import photo4 from "../images/adi_jaffe.webp";
import xyz2 from "../images/xyz2.jpg";
import Divider from "@mui/material/Divider";
// import photo8 from "../images/mental-health-heart-icon.webp";
// import photo9 from "../images/physical-health-icon.webp";
import image from "../images/logo3.png";
// import community from "../images/community.webp";
// import tracking from "../images/tracking.webp";
// import coaching from "../images/coaching.webp";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AccessibilityNewOutlinedIcon from "@mui/icons-material/AccessibilityNewOutlined";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import { useState } from "react";
// import FormControlContext from "@mui/material/FormControl/FormControlContext";
// import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import firstimg2 from "../images/firstimg2.jpg";

import secimg from "../images/secimg.png";
import thirdimg from "../images/thirdimage.png";
import fourthimg from "../images/fourthimage.png";

import CloseIcon from "@mui/icons-material/Close"
import HTTPServices from "../services/HTTPServices";
// import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid';
import familyicon from "../images/familyicon.jpg";
import careicon from "../images/careicon.jpg";
// import science from "../images/THE SCIENCE.png"; 
// import science1 from "../images/THE SCIENCE1.png"; 
import science2 from "../images/science2.png"
import science3 from "../images/science3.png"








export default function Home() {
 let http = new HTTPServices();


  const [open, openchange] = useState(false);
  const functionopenpopup = () => {
    openchange(true);
  };
  const closepopup = () => {
    openchange(false);
  };

  const [CurrentRole, setCurrentRole] = React.useState("");
  const [CurrentName, setCurrentName] = React.useState("");
  const [CurrentEmail, setCurrentEmail] = React.useState("");

  const handleRoleChange = (event) => {
    setCurrentRole(event.target.value);
  };
  const handleNameChange = (event) => {
    setCurrentName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setCurrentEmail(event.target.value);
  };

  const submitFormData = () => {
    console.log(CurrentRole);
    console.log(CurrentName);
    console.log(CurrentEmail);
     let data = {
      name : CurrentName,
      email : CurrentEmail,
      role : CurrentRole,
      agree : true

    }
    var res = http.postFormData(data);
    if (res.length>0){
      console.log("successful")
    }else{
      console.log("faild")
    }
    
    console.log(res)
  };

  return (
    <div>
      {/* // FIRST-CONTAINER
    // FIRST-CONTAINER
    // FIRST-CONTAINER */}

      <Header />
      
      <Grid
        container
        spacing={2}
        sx={{
          bgcolor: "#FBF7E9",
          padding: {
            xs: "20px",
            sm: "30px",
            md: "20px",
            lg: "50px 0px 0px 90px",
          }, // Adjust padding for responsiveness
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={6.5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" }, // Center-align text on small screens
            textAlign: { xs: "center", md: "left" }, // Center text on small screens
            fontWeight: "bolder",
            padding: { xs: "0px", sm: "0px", md: "100px", lg: "0px" },
          }}
        >
          <Typography
            sx={{
              fontWeight: "900",
              fontFamily: "poppins",
              color: "#125782",
              fontSize: { xs: "36px", sm: "45px", md: "65px" }, // Responsive font size
              lineHeight: { xs: "44px", sm: "55px", md: "70px" }, // Responsive line height
            }}
          >
            At Your Fingertips Dementia Care doesn’t have to feel empty
          </Typography>

          <Typography
            sx={{
              marginTop: "20px",
              fontSize: { xs: "16px", sm: "20px", md: "23px" }, // Responsive font size
              fontWeight: "bold",
            }}
          >
            DementiaWhiz app will fill in the gaps of effective Dementia Care by
            providing needed information at your fingertips and with one sign
            in.
          </Typography>

          <Button
            // onClick={functionopenpopup}
            sx={{
              marginTop: "20px",
              width: { xs: "100%", sm: "auto", md: "80%" },
              maxWidth: "411px",
              height: "50px",
              bgcolor: "#b31c31",
              alignItems: "center",
              color: "white",
              '&:hover': {
                bgcolor: '#b31c31', // Background color on hover
                color: 'white', // Text color on hover
              },
              fontSize: { xs: "16px", sm: "18px", md: "20px" }, // Responsive font size
              fontWeight: "700",
              fontFamily: "poppins",
            }}
          >
            Take a Survey
          </Button>

          <Dialog
            bgcolor="#FBF7E9"
            // fullScreen
            open={open}
            onClose={closepopup}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>
              User Registeration{" "}
              <IconButton onClick={closepopup} style={{ float: "right" }}>
                <CloseIcon color="#FFE636"></CloseIcon>
              </IconButton>{" "}
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2} margin={2}>
                <TextField
                  variant="outlined"
                  label="Name"
                  name="Name"
                  value={CurrentName}
                  onChange={handleNameChange}
                ></TextField>
                <TextField
                  variant="outlined"
                  label="Email"
                  name="Email"
                  value={CurrentEmail}
                  onChange={handleEmailChange}
                ></TextField>
                <FormControl required sx={{ m: 2, minWidth: 150 }}>
                  <InputLabel id="demo-simple-select-required-label">
                    Current-Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    name="Currentrole"
                    id="demo-simple-select-required"
                    value={CurrentRole}
                    label="CurrentRole"
                    onChange={handleRoleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Caregiver</MenuItem>
                    <MenuItem value={20}>Family Member</MenuItem>
                    <MenuItem value={30}>Patient</MenuItem>
                    <MenuItem value={40}>Assisted Living Facility</MenuItem>
                    <MenuItem value={50}>Supporter</MenuItem>
                  </Select>
                  <FormHelperText>Required</FormHelperText>
                </FormControl>
                <FormControlLabel
                  control={<Checkbox defaultChecked></Checkbox>}
                  label="Agree terms & conditions"
                ></FormControlLabel>
                {/* bgcolor="#125782" */}
              <Button
                onClick={submitFormData}
                sx={{
                  
                  bgcolor: "#b31c31",
              color: "white",
              '&:hover': {
                bgcolor: '#b31c31', // Background color on hover
                color: 'white', // Text color on hover
              },
                  fontSize: "18px",
                  fontWeight: "700",
                  fontFamily: "poppins",
                }}
              >
                Submit
              </Button>
              </Stack>
            </DialogContent>
            <DialogActions>
              {/* <Button color="success" variant="contained">Yes</Button>
                    <Button onClick={closepopup} color="error" variant="contained">Close</Button>  */}
            </DialogActions>
          </Dialog>
        </Grid>

        <Grid item xs={12} md={12} lg={5} sx={{ padding: "0px 0px 30px 0px" }}>
          <img
            src=
            // {firstimg}
            // {firstimg1}
            {firstimg2}
            // {firstimg3}
            

            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "560px",
              height: "auto",
              borderRadius: "30px",
            }}
          />
        </Grid>
      </Grid>
     











      {/* // second-container
// second-container
// second-container
// second-container */}

     
     
      <Grid
        container
        sx={{
          height: "auto", // Adjusted for flexibility
          bgcolor: "#125782",
          padding: { xs: "20px", sm: "30px", md: "50px 25px" }, // Responsive padding
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            color: "#ffffff",
            fontSize: { xs: "12px", sm: "15px" }, // Responsive font size
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <Item>THE SCIENCE</Item>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            marginTop: { xs: "10px", sm: "2px" }, // Responsive margin
            color: "#ffffff",
            fontWeight: "800",
            fontSize: { xs: "24px", sm: "36px", md: "46px" }, // Responsive font size
            textAlign: "center",
          }}
        >
          <Item>Powered by experience, backed by commitment of care.</Item>
        </Grid>

        <Grid
          item
          xs={12}
          lg = {12}
          // sx={{
          //   color: "#ffffff",
          //   fontSize: { xs: "14px", sm: "18px" }, // Responsive font size
          //   margin: { xs: "0px 20px", sm: "0px 159px" }, // Responsive margin
          //   textAlign: "center",
          //   padding: { xs: "10px 0", sm: "10px 10px 0px 170px" }, // Responsive padding
          // }}
        >
          <Typography 
          xs={12}
          
          sx={{
            color: "#ffffff",
            fontSize: { xs: "14px", sm: "18px" }, // Responsive font size
            margin: { xs: "0px 20px", sm: "0px 159px" }, // Responsive margin
            textAlign: "center",
            padding: { xs: "10px 0", sm: "10px 10px 0px 10px", lg: "10px 10px 0px 10px" }, // Responsive padding
          }}>
          A majority of caregivers (70%) report that coordination of care is stressful.
          </Typography>
        </Grid>

        {/* <Grid
          container
          item
          xs={12}
          spacing={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: { xs: "20px", sm: "30px", lg: "20px" }, // Responsive margin
          }}
         >
          <Grid
            item
            xs={10}
            sm={4}
            md={2.4}
            lg={4}
            sx={{ textAlign: "center", display: "flex" }}
          >
            <Grid item
             xs={1}
             sm={4}
             md={2.4}
             lg={4}
            >
              <img
                src={drink}
                alt="logo"
                style={{ 
                  maxWidth: "100%",
                   height: { xs: "30px", sm: "200px", md: "135px" , lg: "150px"}, // Responsive height

                   }} // Responsive image
              />
            </Grid>
            <Typography
              sx={{
                fontSize: { xs: "45px", sm: "40px", md: "62px" }, // Responsive font size
                fontWeight: "900",
                color: "#FFE635",
              }}
            >
              53%
              <Typography
                sx={{
                  fontSize: { xs: "18px", sm: "18px" }, // Responsive font size
                  fontWeight: "bold",
                  color: "#FFFFFF",
                  textAlign: { xs: "left", sm: "left", lg: "center" },
                }}
              >
              More than half of the caregivers surveyed said that navigating health care was difficult              </Typography>
            </Typography>
          </Grid>

          <Grid
            item
            xs={10}
            sm={4}
            md={2.4}
            lg={4}
            sx={{ textAlign: "center", display: "flex" }}
          >
            <Item>
              <img
                src={drink2}
                alt="logo"
                style={{ maxWidth: "100%", height: "auto" }} // Responsive image
              />
            </Item>

            <Item sx={{ justifyContent: "flex-start" }}>
              <Typography
                sx={{
                  fontSize: { xs: "45px", sm: "40px", md: "62px" }, // Responsive font size
                  fontWeight: "900",
                  color: "#FFE635",
                }}
              >
                66%
                <Typography
                  sx={{
                    fontSize: { xs: "18px", sm: "18px" }, // Responsive font size
                    fontWeight: "bold",
                    color: "#FFFFFF",
                    textAlign: { xs: "left", sm: "left", lg: "center" },
                  }}
                >
         2 in 3 caregivers also have difficulty finding resources and support for their needs                </Typography>
              </Typography>
            </Item>
          </Grid>
        </Grid> */}

<Grid container
xs={12}
sm={12}
md={10}
lg={10}
sx={{display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: { xs: "20px", sm: "30px" } }}>


<Grid item 
xs={12}
sm={12}
md={6}
lg={5}>
            <img
              src={science2}
              alt="logo"
              style={{ maxWidth: "100%", height: "auto" }} // Responsive image
              // sx={{}}
            />
</Grid>

<Grid item
xs={12}
sm={12}
md={6}
lg={5}
 >
             <img
              src={science3}
              alt="logo"
              style={{ maxWidth: "100%", height: "auto" }} // Responsive image
              // sx={{objectFit: "contain"}}
            />
</Grid>


  </Grid>





        <Grid
          item
          xs={12}
          lg={5}
          sx={{ textAlign: "center", marginTop: { xs: "20px", sm: "30px" } }}
        >
          <Item>
            <Button
              sx={{
                bgcolor: "#b31c31",
                color: "white",
              '&:hover': {
                bgcolor: '#b31c31', // Background color on hover
                color: 'white', // Text color on hover
              },
                height: "50px",
                width: { xs: "370px", sm: "auto", lg: "400px" },
                maxWidth: { xs: "315px", sm: "auto", lg: "410px" },
                fontSize: { xs: "16px", sm: "20px" }, // Responsive font size
                fontWeight: "700",
                fontFamily: "poppins",
                paddingTop: "11px",
              }}
            >
              Take a survey
            </Button>
          </Item>
        </Grid>
      </Grid>

     
      {/* fifth-Container  */}
      {/* fifth-Container 
      fifth-Container 
      fifth-Container 
      fifth-Container 
      fifth-Container 
      fifth-Container  */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          bgcolor: "#FBF7E9",
          padding: "50px 27px 0px 27px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            bgcolor: "#FBF7E9",
            height: "22px",
            color: "#000000",
            fontSize: "15px",
            fontWeight: "700",
            margin: "0px 0px 8px 0px",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              bgcolor: "FBF7E9",
              height: "22px",
              color: "#000000",
              fontSize: { xs: "18px", sm: "15px", md: "15px", lg: "15px" },
              fontWeight: { xs: "500", sm: "600", md: "700", lg: "700" },
              margin: "0px 0px 8px 0px",
              textAlign: "center",
            }}
          >
            The Benefits
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={10}
          sx={{
            bgcolor: "#FBF7E9",
            height: "52px",
            color: "#000000",
            fontSize: "46px",
            fontWeight: "bolder",
            margin: "0px 0px 20px 0px",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              bgcolor: "#FBF7E9",
              height: { xs: "195px", sm: "52px", md: "52px", lg: "52px" },
              color: "#000000",
              fontSize: "46px",
              fontWeight: "bolder",
              margin: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 50px 0px",
                md: "0px 0px 50px 0px",
                lg: "0px 0px 50px 0px",
              },
              textAlign: "center",
              lineHeight: { xs: "47px", sm: "52px", md: "52px", lg: "52px" },
            }}
          >
            The most supportive version of Dementia care.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={9}
          sx={{
            bgcolor: "#FBF7E9",
            color: "#000000",
            height: "52px",
            fontSize: "18px",
            margin: {
              xs: "0px 0px 50px 0px",
              sm: "0px 0px 50px 0px",
              md: "0px 0px 50px 0px",
              lg: "0px 0px 30px 0px",
            },
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              bgcolor: "#FBF7E9",
              color: "#000000",
              height: "52px",
              fontSize: "18px",
              margin: {xs: "0px 0px 50px 0px", sm: "0px 0px 50px 0px", md: "0px 0px 50px 0px", lg: "0px 0px 0px 0px"},
              textAlign: "center",
            }}
          >
           Coordination of care is stressful enough. DementiaWhiz captures the most detail in one app in order to improve health outcomes for our most vulnerable senior population.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={11.5}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 50px 0px",
              sm: "0px 0px 50px 0px",
              md: "0px 0px 50px 0px",
              lg: "0px 0px 0px 100px",
            },
            margin: "30px 0px 0px 0px",
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
            alignItems: "left",
            gap: "20px",
          }}
        >
          {/* Benefits Cards  */}
          {[
            {
              image: xyz2,
              title: "For Patients Enhanced Care with No Pressure:",
              benefits: [
                "Provide better support in managing their lived experience",
                "Improve personal hygiene outcomes",
                "Reduce behavioral instances of confusion and anxiety",
              ],
            },
            {

              image: familyicon,
              title: "For Family Members Improved Peace of Mind:",
              benefits: [
                "Better success rates for more accomplished caregiving",
                "Improvements in loved one's mental health",
                "Experience reduced caregiving stress knowing you made the correct decision",
              ],
            },
            {
              image: careicon,
              title: "For Caregivers Elevated Care Efficiency and Focus:",
              benefits: [
                "Confidently providing improved care coordination",
                "Enhanced productivity in care routines",
                "Shared resources specific to person living with Dementia",
              ],
            },
          ].map((card, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3.7}
              key={index}
              sx={{
                bgcolor: "white",
                padding: "30px 20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: 1,
                borderRadius: 1,
              }}
            >
              <img
                src={card.image}
                alt="benefit"
                style={{ width: "97px", height: "67px", marginBottom: "10px" }}
              />
              <Typography
                sx={{
                  fontSize: "20px",
                  margin: "0px 0px 5px 0px",
                  fontWeight: "800",
                }}
              >
                {card.title}
              </Typography>

              <Divider
                sx={{
                  width: "100%",
                  height: "10px",
                  margin: "8px 0px 14px 0px",
                }}
              />

              <Typography
                sx={{
                  fontSize: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  textAlign: "left",
                }}
              >
                {card.benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </Typography>
            </Grid>
          ))}
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            justifyContent: "flex-end",
            alignItems: "flex-start",
            margin: "0px 0px 50px 0px",
          }}
        >
          <Button
            onClick={functionopenpopup}
            sx={{
              bgcolor: "#a97adb",
              fontFamily: "poppins",
              color: "white",
              '&:hover': {
                bgcolor: '#a97adb', // Background color on hover
                color: 'white', // Text color on hover
              },
              fontSize: { xs: "14px", sm: "14.5px", md: "18px", lg: "20px" },
              fontWeight: "700",
              height: "50px",
              padding: "10px 30px",
              textAlign: "center",
              margin: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "30px 0px 0px 0px",
              },
            }}
          >
            Join DementiaWhiz Today
          </Button>
        </Grid>
      </Grid>

      
      {/* sixth container  */}
      {/* sixth container
      sixth container
      sixth container
      sixth container
      sixth container
      sixth container */}

      <Grid
        container
        spacing={2}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          margin: "50px 0px",
          padding: { xs: "0px 0px", md: "0px 130px" },
        }}
      >
        <Grid
          container
          item
          xs={11}
          lg={12}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Typography
            sx={{ fontWeight: "550", fontSize: { xs: "14px", md: "15px" } }}
          >
            THE FEATURES
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={11}
          lg={12}

          sx={{ justifyContent: "center", alignItems: "center" }}
         >
          <Typography
            sx={{
              fontWeight: "900",
              fontSize: { xs: "28px", sm: "36px", md: "46px" },
              textAlign: "center",
            }}
          >
            Experience the Improved Shift in Care
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={11}
          lg={12}

          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: { xs: "14px", md: "18px" },
              textAlign: "center",
              margin: { xs: "8px 16px", md: "8px 128.50px" },
            }}
          >
            DementiaWhiz safeguards the person's preferences, legal paperwork,
            medical, emotional, and physical history, personalized tips and
            techniques for informed personal care, and so much more. This app
            will revolutionize care for those unable to recall in everyday or
            emergency situations. DementiaWhiz also provides educational
            components with simple searchable terms for access to specific
            hand-picked videos and/or specific easy reading materials.
          </Typography>
        </Grid>

        <Divider sx={{ width: "100%", height: "10px", margin: "30px 0px" }} />

        <Grid container item spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                color: "#B05DF1",
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: "bold",
                textAlign: "left",
                padding: { xs: "0px 20px", md: "0px 20px" , lg: "0px 20px" },
              }}
            >
              COMMUNITY
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "24px", md: "32px" },
                fontWeight: "bold",
                textAlign: { xs: "left", md: "left", lg: "left" },
                padding: "0px 20px",
              }}
            >
              Information
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "14px", md: "16px" },
                textAlign: { xs: "left", md: "left", lg: "left" },
                padding: "20px 20px",
              }}
            >
              You could have easy access to Medical Information, Admission
              Paperwork, Demographics, Family Information and Contacts, Personal
              History, Preferences, Allergies, Personalized Activities of Daily
              Living, A Toolbox of Educational Tips and Techniques, and Videos,
              and Articles
            </Typography>
            <Button
              sx={{
                width: "100%",
                maxWidth: { xs: "290px", md: "200px", lg: "283px" },
                height: "50px",
                bgcolor: "#b31c31",
                color: "white",
              '&:hover': {
                bgcolor: '#b31c31', // Background color on hover
                color: 'white', // Text color on hover
              },
                fontSize: "20px",
                fontWeight: "600",
                marginTop: "20px",
              }}
            >
              Take a Survey
            </Button>
          </Grid>
          <Grid item xs={12} md={6}
           sx={{ 
             padding: { xs: "0px 20px 0px 20px", md: "0px 20px 0px 20px" },
            textAlign: {xs : "center", md: "left", },
            }}>
            <img
              src={secimg}
              alt="logo"
              style={{
                width: "100%",
                maxWidth: "336px",
                height: "auto",
                borderRadius: "30px",
                padding:{xs: "0px 0px 0px 0px", md: "0px 0px 0px 0px"}
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ width: "100%", height: "10px", margin: "30px 0px" }} />

        <Grid container item spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                color: "#B05DF1",
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: "bold",
                textAlign: "left",
                padding: "25px 20px",
              }}
            >
              TRACKING
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "24px", md: "32px" },
                fontWeight: "bold",
                textAlign: "left",
                padding: "0px 20px",
              }}
            >
              One Stop App
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "14px", md: "16px" },
                textAlign: "left",
                padding: "20px 20px",
              }}
            >
              Don’t always have the client information you would need in one
              place with built in search capabilities? It is in there!
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "14px", md: "16px" },
                textAlign: "left",
                padding: "20px 20px",
              }}
            >
              Feeling as though you could use a bit more education, training, or
              hints on what to do in certain situations? It is in there!
            </Typography>
            <Button
              sx={{
                width: "100%",
                maxWidth: "283px",
                height: "50px",
                bgcolor: "#b31c31",
                color: "white",
                '&:hover': {
                  bgcolor: '#b31c31', // Background color on hover
                  color: 'white', // Text color on hover
                },
                fontSize: "20px",
                fontWeight: "600",
                marginTop: "20px",
              }}
            >
              Take A Survey
            </Button>
          </Grid>
          <Grid item xs={12} md={6} 
          sx={{
             textAlign: "center" ,
             padding: { xs: "0px 20px 0px 20px", md: "0px 20px 0px 20px" },

          }}>
            <img
              src={thirdimg}
              alt="logo"
              style={{
                width: "100%",
                maxWidth: "336px",
                height: "auto",
                borderRadius: "30px",
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ width: "100%", height: "10px", margin: "30px 0px" }} />

        <Grid container item spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                color: "#B05DF1",
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: "bold",
                textAlign: "left",
                padding: "25px 20px",
              }}
            >
              COACHING
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "24px", md: "32px" },
                fontWeight: "bold",
                textAlign: "left",
                padding: "0px 20px",
              }}
            >
              Convenience and Confidence
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "14px", md: "16px" },
                textAlign: "left",
                padding: "20px 20px",
              }}
            >
              Know that you are getting the most current information and
              details.
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: { xs: "14px", md: "16px" },
                textAlign: "left",
                padding: "20px 20px",
              }}
            >
              Securing the private and helpful details to build trusting
              relationships of care.
            </Typography>
            <Button
              sx={{
                width: "100%",
                maxWidth: "283px",
                height: "50px",
                bgcolor: "#b31c31",
                color: "white",
              '&:hover': {
                bgcolor: '#b31c31', // Background color on hover
                color: 'white', // Text color on hover
              },
                fontSize: "20px",
                fontWeight: "600",
                marginTop: "20px",
              }}
            >
              Take A Survey
            </Button>
          </Grid>
          <Grid item xs={12} md={6} 
          sx={{
             textAlign: "center" ,
             padding: { xs: "0px 20px 0px 20px", md: "0px 20px 0px 20px" },
             }}>
            <img
              src={fourthimg}
              alt="logo"
              style={{
                width: "100%",
                maxWidth: "336px",
                height: "auto",
                borderRadius: "30px",
              }}
            />
          </Grid>
        </Grid>
      </Grid>

     
 {/* 
seventh container 
seventh container 
seventh container 
seventh container  
*/}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          bgcolor: "#B05DF1",
          justifyContent: "center",
          alignItems: "center",
          padding: { xs: "40px 20px", md: "60px 40px", lg: "80px 80px" },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            bgcolor: "#B05DF1",
            fontSize: "15px",
            color: "white",
            fontWeight: "700",
            textAlign: "center",
            mb: 2,
          }}
        >
          WE MEAN IT
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            bgcolor: "#B05DF1",
            textAlign: "center",
            mb: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "32px", md: "38px", lg: "46px" },
              fontWeight: "800",
              color: "white",
            }}
          >
            Our commitment to you.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={10}
          lg={8}
          sx={{
            bgcolor: "#B05DF1",
            textAlign: "center",
            mb: 4,
            mx: "auto",
          }}
        >
          <Typography
            sx={{ fontSize: "18px", fontWeight: "900", color: "white" }}
          >
            We understand the deeply personal journey of caring for someone with
            dementia. We adhere to a strict code of conduct and promise to
            always prioritize your peace of mind and well-being above all else.
          </Typography>
        </Grid>

        <Grid
          item
          container
          xs={12}
          spacing={3}
          sx={{
            bgcolor: "#B05DF1",
            justifyContent: "center",
            alignItems: "center",
            mb: 6,
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3.5}
            sx={{
              margin: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "5px 25px 0px 0px",
              },
            }}
          >
            <FavoriteBorderIcon
              sx={{ height: "50px", width: "50px", color: "white" }}
            />
            <Typography
              sx={{
                color: "white",
                fontWeight: "700",
                fontSize: "22px",
                mt: 0,
              }}
            >
              Supportive Care for All
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontWeight: "600",
                fontSize: "16px",
                mt: 1,
              }}
            >
              DementiaWhiz celebrates every small win in your caregiving
              journey. We focus on positive reinforcement and encouragement,
              never on guilt.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3.5}
            sx={{
              margin: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 25px 20px 0px",
              },
            }}
          >
            <LockOutlinedIcon
              sx={{ height: "50px", width: "50px", color: "white", mt: 1 }}
            />
            <Typography
              sx={{
                color: "white",
                fontWeight: "700",
                fontSize: "22px",
                mt: 0,
                mb: 1,
              }}
            >
              Top Priority on Privacy
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontWeight: "600",
                fontSize: "16px",
                mb: 1,
              }}
            >
              Your data and journey are personal and private. We enforce the
              highest standards of security to protect your information.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3.5}
            sx={{
              margin: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 0px",
              },
            }}
          >
            <AccessibilityNewOutlinedIcon
              sx={{ height: "50px", width: "50px", color: "white", mt: 1 }}
            />
            <Typography
              sx={{
                color: "white",
                fontWeight: "700",
                fontSize: "22px",
                mt: 1,
                mb: 1,
              }}
            >
              Easy and Accessible
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontWeight: "600",
                fontSize: "16px",
                mb: 2,
                mt: 0,
              }}
            >
              DementiaWhiz is designed to be user-friendly for all ages and tech
              abilities. Our support team is always available to help you
              navigate the app.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3.5}
            sx={{
              margin: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 25px 0px 0px",
              },
            }}
          >
            <ThumbUpOffAltOutlinedIcon
              sx={{ height: "50px", width: "50px", color: "white" }}
            />
            <Typography
              sx={{
                color: "white",
                fontWeight: "700",
                fontSize: "22px",
                mt: 0,
              }}
            >
              We will work with you to ensure customer satisfaction
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontWeight: "600",
                fontSize: "16px",
                mt: 1,
              }}
            >
              Try DementiaWhiz risk-free for 30 days. If you're not happy, we'll
              ensure a hassle-free refund.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3.5}
            sx={{
              margin: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 25px 0px 0px",
              },
            }}
          >
            <BackHandOutlinedIcon
              sx={{
                height: "50px",
                width: "50px",
                color: "white",
                mt: 2,
                mb: 0.8,
              }}
            />
            <Typography
              sx={{
                color: "white",
                fontWeight: "700",
                fontSize: "22px",
                mt: 0,
              }}
            >
              Empowerment in Your Hands
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontWeight: "600",
                fontSize: "16px",
                mt: 1,
              }}
            >
              Adjust your caregiving plans and schedules as needed. Customize
              your goals and daily tasks to fit your unique needs.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3.5}
            sx={{
              margin: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 0px",
              },
            }}
          >
            <MedicalServicesOutlinedIcon
              sx={{ height: "50px", width: "50px", color: "white", mt: 1 }}
            />
            <Typography
              sx={{
                color: "white",
                fontWeight: "700",
                fontSize: "22px",
                mt: 1,
                mb: 1,
              }}
            >
              Ongoing Care Excellence
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontWeight: "600",
                fontSize: "16px",
                mt: 1,
                mb: 2,
              }}
            >
              Regular updates with the latest dementia care practices and
              research. 24/7 support to assist you whenever you need it.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

     
      {/*
eighth container 
eighth container 
eighth container 
eighth container  */}

      <Grid
        container
        sx={{
          bgcolor: "white",
          margin: "0px",
          padding: {
            xs: "20px 15px",
            sm: "30px 20px",
            md: "50px 27px",
            lg: "50px 27px 20px 27px",
          },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            bgcolor: "white",
            margin: "0px",
            padding: {
              xs: "20px 15px",
              sm: "30px 20px",
              md: "40px 50px 80px 50px",
              lg: "0px 0px 20px 50px",
            },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              bgcolor: "white",
              color: "green",
              margin: "0px 0px 40px 0px",
              height: "60px",
              width: "290px",
              display: "flex",
              textAlign: "left",
            }}
          >
            <img
              src={image}
              alt="logo"
              style={{ width: "70px", height: "60px" }}
            />
            <Typography
              sx={{
                fontSize: { xs: "24px", md: "30px" },
                fontWeight: "bold",
                color: "#125782",
                padding: "10px 0px 0px 5px",
              }}
            >
              DementiaWhiz
            </Typography>
          </Grid>

          <Grid
            container
            spacing={4}
            sx={{
              bgcolor: "white",
              color: "black",
              padding: "0px",
              textAlign: "left",
            }}
          >
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={2}
              sx={{
                bgcolor: "white",
                margin: { xs: "0px 0px", md: "0px 100px 0px 0px" },
              }}
            >
              <Link
                href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "12px",
                  height: "32px",
                }}
              >
                Explore
              </Link>
              <Divider style={{ margin: "16px 0px 20px 0px" }} />
              <Link
                // href="#"
                style={{ textDecoration: "none", color: "black", fontSize: "12px", height: "32px" }}
              >
                Home
              </Link>
              <br />
              <Link
                // href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                  height: "32px",
                }}
              >
                Blog
              </Link>
              <br />
              <Link
                // href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                  height: "32px",
                }}
              >
                Reviews
              </Link>
              <br />
              <Link
                // href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                  height: "32px",
                }}
              >
                About
              </Link>
              <br />
              <Link
                // href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                  height: "32px",
                }}
              >
                Help & Support
              </Link>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={2}
              sx={{
                bgcolor: "white",
                margin: { xs: "0px", md: "0px 100px 0px 0px" },
                textAlign: "left",
              }}
            >
              <Link
                href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Get in Touch
              </Link>
              <Divider style={{ margin: "16px 0px 20px 0px" }} />
              <Link
                href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                }}
              >
                Instagram
              </Link>
              <br />
              <Link
                href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                }}
              >
                Twitter
              </Link>
              <br />
              <Link
                href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                }}
              >
                LinkedIn
              </Link>
              <br />
              <Link
                href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                }}
              >
                Facebook
              </Link>
              <br />
              <Link
                href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                }}
              >
                Contact Us
              </Link>
              <br />
              {/* <Link
                href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "12px",
                }}
              >
                Contact Press
              </Link> */}
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sx={{
                bgcolor: "white",
                color: "black",
                margin: "0px",
                textAlign: "left",
                padding: { xs: "0px 0px 0px 0px", md: "0px 0px 0px 0px" },  
              }}
            >
              <Link
                href="#"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                About DementiaWhiz
              </Link>
              <Divider style={{ margin: "16px 0px 20px 0px" }} />
              <Typography style={{ color: "black", fontSize: "13px" }}>
                DementiaWhiz was created to provide ease of access to important
                information about a person with cognitive decline whether they
                live inside a community or continue to live on their own.
              </Typography>
              <br />
              <Typography
                style={{ color: "black", fontSize: "13px" }}>
                DementiaWhiz safeguards the person's preferences, legal
                paperwork, medical, emotional, and physical history,
                personalized tips and techniques for informed personal care, and
                so much more. This app will revolutionize care for those unable
                to recall in everyday or emergency situations.
                </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              bgcolor: "white",
              color: "black",
              margin: "40px 0px 40px 0px",
              textAlign: "left",
            }}
          >
            <Divider sx={{ margin: "50px 0px 20px 0px", color: "black" }} />

            <Grid
              container
              sx={{
                bgcolor: "white",
                display: "flex",
                margin: "30px 0px 0px 0px",
              }}
            >
              <Grid
                item
                xs={12}
                lg={4}
                sx={{ bgcolor: "white", display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    color: "#919191",
                    fontSize: { xs: "12px", md: "14px" },
                    margin: "0px",
                  }}
                >
                  Copyright 2024 DementiaWhiz. All rights reserved.
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                lg={8}
                sx={{
                  bgcolor: "white",
                  display: "flex",
                  justifyContent: { xs: "center", lg: "right" },
                  fontSize: { xs: "12px", md: "14px" },
                  margin: "0px",
                }}
                style={{ color: "#919191" }}
              >
                <Link
                  href="#"
                  style={{ color: "#919191", marginRight: "10px" }}
                >
                  Privacy Policy
                </Link>
                |
                <Link href="#" style={{ color: "#919191", margin: "0px 10px" }}>
                  Terms & Conditions
                </Link>
                |
                <Link href="#" style={{ color: "#919191", marginLeft: "10px" }}>
                  Cookie Policy
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

          </div>
  );
}
