// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table th {
    background-color: #65a7e9 !important;;
    color: #ffffff; 
    text-align: center;
    vertical-align: middle;
    padding: 10px;
    border: 1px solid #dee2e6;
  }

  .table td {
    text-align: center;
    border: 1px solid #dee2e6;
  }
  
  .table-hover tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  .btn-info {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-warning {
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .card {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .card-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    padding: 10px 20px;
  }
  
  .card-title {
    font-size: 18px;
  }
  
  .card-tools {
    display: flex;
    justify-content: flex-end;
  }
  
  .card-body {
    padding: 15px;
  }
  
  .table-responsive {
    margin-top: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/style/AlfTable.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,cAAc;IACd,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,uCAAuC;EACzC;;EAEA;IACE,yBAAyB;IACzB,gCAAgC;IAChC,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".table th {\n    background-color: #65a7e9 !important;;\n    color: #ffffff; \n    text-align: center;\n    vertical-align: middle;\n    padding: 10px;\n    border: 1px solid #dee2e6;\n  }\n\n  .table td {\n    text-align: center;\n    border: 1px solid #dee2e6;\n  }\n  \n  .table-hover tbody tr:hover {\n    background-color: #f5f5f5;\n  }\n  \n  .btn-info {\n    background-color: #007bff;\n    border-color: #007bff;\n  }\n  \n  .btn-warning {\n    background-color: #ffc107;\n    border-color: #ffc107;\n  }\n  \n  .btn-danger {\n    background-color: #dc3545;\n    border-color: #dc3545;\n  }\n  \n  .card {\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .card-header {\n    background-color: #f8f9fa;\n    border-bottom: 1px solid #dee2e6;\n    padding: 10px 20px;\n  }\n  \n  .card-title {\n    font-size: 18px;\n  }\n  \n  .card-tools {\n    display: flex;\n    justify-content: flex-end;\n  }\n  \n  .card-body {\n    padding: 15px;\n  }\n  \n  .table-responsive {\n    margin-top: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
