import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Home1 from './components/Home1';
import Home2 from './components/Home2';
import Home3 from './components/Home3';

import Header from './components/Header';
// import Signin from './pages/Signin'; 
// import Admin from '../src/components/Admin.js';
import About from './pages/About';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';








function App() {
  return (
   
    <div className="App">
    <Router>
     <Routes>
        <Route path="/navbar" element={<Navbar />} />
        <Route path="/" element={<Home />} />
        <Route path="/home1" element={<Home1 />} />
        <Route path="/home2" element={<Home2 />} />
        <Route path="/home3" element={<Home3 />} />
        <Route path="/About" element={<About />} />
        <Route path="/header" element={<Header />} />
        {/* <Route path="/admin" element={<Signin />} /> */}
        {/* <Route path="/dashboard" element={<Admin />} /> */}

        {/* <Route path="/newhome" element={<Newhome />} /> */}
     </Routes>
    </Router>


    

    </div>
  );
}

export default App;
