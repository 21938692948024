// src/App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import Home from './components/Home';
import FetchData from './components/FetchData';
import Login from './components/Login';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Dashboard from './components/Dashboard'; // Import Dashboard component
import CreateAlf from './components/Alf/CreateAlf';
import FetchAlfData from './components/Alf/FetchAlfData'; // ALF component
import AlfDetails from './components/Alf/AlfDetails';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [data, setData] = useState([]);
  const [alfData, setAlfData] = useState([]); // State for ALF data
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [token]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken(null);
    axios.defaults.headers.common['Authorization'] = '';
    navigate('/admin'); // Redirect to login page
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}api/enquiries`);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  const fetchAlfData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}api/alfData`);
      console.log("response.data in APP.jS", JSON.stringify(response.data))
      setAlfData(response.data);
    } catch (error) {
      console.error('Error fetching ALF data:', error);
    }
    setLoading(false);
  };

  // useEffect to refetch ALF data on certain conditions
  // useEffect(() => {
  //   if (token) {
  //     fetchAlfData();
  //   }
  // }, [token]);

  // Define the onSuccess callback
  const handleCreateAlfSuccess = async () => {
    await fetchAlfData(); // Fetch the latest data
    navigate('/fetch-alf-data'); // Navigate to the ALF data page
  };

  return (
    <div className="wrapper">
      {token ? (
        <>
          <Sidebar 
            onFetchData={fetchData} 
            onFetchAlfData={fetchAlfData} 
            onLogout={handleLogout} 
          />
          <div className="content-wrapper">
            <div className="content">
              <div className="container-fluid">
                <Routes>
                  <Route path="/admin" element={<Dashboard />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/fetch-data" element={<FetchData data={data} loading={loading} />} />
                  <Route 
                    path="/fetch-alf-data" 
                    element={
                      <FetchAlfData 
                        data={alfData} 
                        loading={loading} 
                        onRefresh={fetchAlfData} // Pass refresh function
                      />
                    } 
                  />
                  <Route
                    path="/add-new-alf"
                    element={<CreateAlf onSuccess={handleCreateAlfSuccess} />}
                  />
                  <Route path="/alf-details/:alfId" element={<AlfDetails />} />
                </Routes>
              </div>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Login setToken={setToken} />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
