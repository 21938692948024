import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../style/AlfTable.css';

const AlfTable = ({ data, onDetails, onArchive, onDelete }) => {
  console.log("I AM HERE!", JSON.stringify(data));
  const navigate = useNavigate();
  const [localData, setLocalData] = useState(data);

  // Update localData whenever the `data` prop changes
  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const handleDetails = (alfId) => {
    navigate(`/alf-details/${alfId}`); // Navigate to the details page
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">ALFs</h3>
        <div className="card-tools">
          <button 
            type="button" 
            className="btn btn-primary" 
            onClick={() => navigate('/add-new-alf')}
          >
            Add New ALF
          </button>
        </div>
      </div>
      <div className="card-body table-responsive p-0">
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Name</th>
              <th>Details</th>
              <th>Archive</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {localData.map((alf, index) => (
              <tr key={alf.id} className="alf-row">
                <td>{index + 1}</td> {/* Serial number */}
                <td>{alf.alf_name}</td>
                <td>
                  <button 
                    className="btn btn-info" 
                    onClick={() => handleDetails(alf._id)}
                  >
                    Details
                  </button>
                </td>
                <td>
                  <button 
                    disabled
                    className="btn btn-warning" 
                    // onClick={() => onArchive(alf.id)}
                  >
                    Archive
                  </button>
                </td>
                <td>
                  <button 
                    disabled
                    className="btn btn-danger" 
                    // onClick={() => onDelete(alf.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AlfTable;
