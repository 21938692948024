import React from "react";
import Header from "../components/Header";
import { Grid, Typography, Button } from "@mui/material";

// import images
import about1 from "../images/about_page_banner.webp";
import about_card1 from "../images/about_card1.png";
import about_circle from "../images/about_circle1.webp";
import cont5 from "../images/5thcont.webp";
import thenewyorktimes from "../images/thenewyorktimes.avif";
import usatoday from "../images/usatoday.avif";
import forbes from "../images/forbes.avif";
import daydrink from "../images/day-drink.jpg";
import mobile from "../images/mobile2.png";

const About = () => {
  return (
    <>
      <Header />

      <Grid
        container
        xs={12}
        md={10}
        lg={12}
        sx={{
          display: "flex",
          padding: {
            xs: "0px 0px 0px 0px",
            sm: "0px 0px 0px 0px",
            md: "0px 0px 80px 88px",
            lg: "0px 0px 80px 88px",
            xl: "0px 0px 80px 88px",
          },
        }}
      >
        <Grid item xs={12} md={5} lg={5}>
          <Typography
            sx={{
              justifyContent: "flex-start",
              alighnItems: "center",

              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "150px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "64px",
                xl: "24px",
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              textAlign: "left",
              lineHeight: {
                xs: "0px ",
                sm: "0px ",
                md: "0px ",
                lg: "64px ",
                xl: "0px ",
              },
            }}
          >
            Mindful drinking is about getting the most out of life.
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={7}>
          <img
            src={about1}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "760px",
              height: "auto",
            }}
          />
        </Grid>
      </Grid>

      {/* 2nd container */}
      {/* 2nd container */}
      {/* 2nd container */}
      {/* 2nd container */}
      {/* 2nd container */}

      <Grid
        container
        sx={{
          bgcolor: "#FBF7E9",
          padding: {
            xs: "0px 0px 0px 0px",
            sm: "0px 0px 0px 0px",
            md: "0px 0px 0px 0px",
            lg: "80px 88px 80px 88px",
            xl: "0px 0px 0px 0px",
          },
          justifyContent: "center",
          alighnItems: "center",
        }}
      >
        <Grid item xs={6} md={12} lg={12}>
          <img
            src={about1}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "42px",
              height: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Typography
            sx={{
              justifyContent: "center",
              alighnItems: "center",

              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "1px 0px 1px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "48px",
                xl: "24px",
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              //   textAlign: "left",
            }}
          >
            What we stand for
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={8.4}
          sx={{
            justifyContent: "center",
            alighnItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              justifyContent: "center",
              alighnItems: "center",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 40px 0px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "right",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "1px 0px 10px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "25px",
                  xl: "24px",
                },
                fontWeight: "200",
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "35px ",
                  xl: "0px ",
                },
              }}
            >
              Some people think that cutting back on drinking is all about
              having less. But at Sunnyside, we’re all about having more. More
              rested mornings, more days when you’re feeling your absolute best,
              more energy and positivity. We’re not here to say “don’t go out”
              or “never drink”. We’re here to help you to enjoy your life AND
              wake up ready to shine your brightest.
            </Typography>
          </Grid>
        </Grid>

        {/* cards starting */}

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ bgcolor: "#FBF7E9", display: "flex", height: "auto" }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 48px 64px 48px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={about_card1}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "291px",
                  height: "138px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                A proactive investment for anyone who drinks
              </Typography>
              {/* </Grid> */}

              <Grid
                item
                xs={12}
                md={12}
                lg={11.5}
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "4px 0px 16px 0px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "21px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "0px ",
                      sm: "0px ",
                      md: "0px ",
                      lg: "25px ",
                      xl: "0px ",
                    },
                  }}
                >
                  We can all benefit from being mindful and intentional about
                  our drinking.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* card 2 */}

          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 48px 64px 48px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={about_card1}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "291px",
                  height: "138px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Mindfulness and balance, not “all or nothing”
              </Typography>
              {/* </Grid> */}

              <Grid item xs={12} md={12} lg={11}></Grid>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "21px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                Unlike a lot of services out there, we’re focused on helping
                build healthy habits with no pressure to cut your drinking down
                to zero.
              </Typography>
            </Grid>
          </Grid>

          {/* card 3 */}

          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 48px 64px 48px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={about_card1}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "291px",
                  height: "138px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Friendly, approachable and judgment free
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} lg={11}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "21px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                We support you 100% with positivity and encouragement on your
                journey, through any ups and downs.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* card4 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ bgcolor: "#FBF7E9", display: "flex", height: "auto" }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 48px 64px 48px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={about_card1}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "291px",
                  height: "138px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Life happens, so we’re flexible
              </Typography>
              {/* </Grid> */}

              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "4px 0px 16px 0px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "21px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "0px ",
                      sm: "0px ",
                      md: "0px ",
                      lg: "25px ",
                      xl: "0px ",
                    },
                  }}
                >
                  You’re the boss. We always allow you to adjust your weekly
                  plans to match your lifestyle or needs.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* card 5 */}

          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 48px 64px 48px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={about_card1}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "291px",
                  height: "138px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                A solution that fits into your unique lifestyle
              </Typography>
              {/* </Grid> */}

              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "4px 0px 16px 0px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "21px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "0px ",
                      sm: "0px ",
                      md: "0px ",
                      lg: "25px ",
                      xl: "0px ",
                    },
                  }}
                >
                  Sunnyside adapts to your starting point, goals and progress,
                  and adjusts your plan accordingly.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* card6 */}

          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 48px 64px 48px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={about_card1}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "291px",
                  height: "138px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Respectful of your privacy, always
              </Typography>
              {/* </Grid> */}

              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "4px 0px 16px 0px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "21px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "0px ",
                      sm: "0px ",
                      md: "0px ",
                      lg: "25px ",
                      xl: "0px ",
                    },
                  }}
                >
                  Sunnyside is a secure platform. The information you share is
                  always confidential. Read more about our approach to privacy
                  and security.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* cards end */}

      {/* 3rd container */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alighnItems: "center",

          padding: {
            xs: "0px 0px 0px 0px",
            sm: "0px 0px 0px 0px",
            md: "0px 0px 0px 0px",
            lg: "80px 88px 80px 88px",
            xl: "0px 0px 0px 0px",
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={8}
          sx={{
            justifyContent: "center",
            alighnItems: "center",
          }}
        >
          <Typography
            sx={{
              justifyContent: "center",
              alighnItems: "center",

              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "48px",
                xl: "24px",
              },
              fontWeight: "500",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              textAlign: "center",
              lineHeight: {
                xs: "0px ",
                sm: "0px ",
                md: "0px ",
                lg: "45px ",
                xl: "0px ",
              },
            }}
          >
            In their first 30 days,
          </Typography>
          <Typography
            sx={{
              justifyContent: "center",
              alighnItems: "center",

              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "48px",
                xl: "24px",
              },
              fontWeight: "500",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              textAlign: "center",
              lineHeight: {
                xs: "0px ",
                sm: "0px ",
                md: "0px ",
                lg: "45px ",
                xl: "0px ",
              },
            }}
          >
            our members experience on average:
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "48px 0px 0px 0px",
              xl: "0px 0px 0px 0px",
            },
            justifyContent: "space-evenly",
            display: "flex",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={2}
            sx={{
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "40px 40px 40px 40px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Typography
              sx={{
                // justifyContent: "center",
                // alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "12px 0px 12px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "64px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "45px ",
                  xl: "0px ",
                },
              }}
            >
              29%
            </Typography>
            <Typography
              sx={{
                // justifyContent: "center",
                // alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "12px 0px 12px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "16px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#828282",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "20px ",
                  xl: "0px ",
                },
              }}
            >
              REDUCTION IN DRINKS
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={1}
            container
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={about1}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "42px",
                height: "auto",
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={2}
            sx={{
              justifyContent: "center",
              alighnItems: "center",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "40px 40px 40px 40px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Typography
              sx={{
                // justifyContent: "center",
                // alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "12px 0px 12px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "64px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "45px ",
                  xl: "0px ",
                },
              }}
            >
              1,500
            </Typography>
            <Typography
              sx={{
                justifyContent: "center",
                // alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "12px 0px 12px 30px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "22px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#828282",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "20px ",
                  xl: "0px ",
                },
              }}
            >
              Calories Avoided
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={1}
            container
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={about1}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "42px",
                height: "auto",
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={2}
            sx={{
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "40px 40px 40px 40px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Typography
              sx={{
                // justifyContent: "center",
                // alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "12px 0px 12px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "64px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "45px ",
                  xl: "0px ",
                },
              }}
            >
              $50+
            </Typography>
            <Typography
              sx={{
                // justifyContent: "center",
                // alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "12px 0px 12px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "14px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#828282",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "20px ",
                  xl: "0px ",
                },
              }}
            >
              Money Saved
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* 4th container */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alighnItems: "center",
          bgcolor: "#FBF7E9",
          padding: {
            xs: "0px 0px 0px 0px",
            sm: "0px 0px 0px 0px",
            md: "0px 0px 0px 0px",
            lg: "80px 88px 80px 88px",
            xl: "0px 0px 0px 0px",
          },
        }}
      >
        <Grid item xs={6} md={12} lg={12}>
          <img
            src={about1}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "42px",
              height: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Typography
            sx={{
              justifyContent: "center",
              alighnItems: "center",

              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "1px 0px 10px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "48px",
                xl: "24px",
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              //   textAlign: "left",
            }}
          >
            The Science
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={7.3}
          sx={{
            justifyContent: "center",
            alighnItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              justifyContent: "center",
              alighnItems: "center",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "right",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "1px 0px 40px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "25px",
                  xl: "24px",
                },
                fontWeight: "200",
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "35px ",
                  xl: "0px ",
                },
              }}
            >
              Sunnyside helps you reach your goals—and stick with them long
              term—by focusing on three key, scientifically-proven superpowers:
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          {/* yellow card 3 */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 16px 16px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={about_circle}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "138px",
                  height: "138px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Pre-commitment
              </Typography>
              {/* </Grid> */}

              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "4px 0px 16px 0px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "21px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "0px ",
                      sm: "0px ",
                      md: "0px ",
                      lg: "25px ",
                      xl: "0px ",
                    },
                  }}
                >
                  Each week you set an intention for the week ahead that
                  includes a tracking goal, a drink goal, and possibly a dry day
                  goal.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* yellow card two */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 16px 16px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={about_circle}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "138px",
                  height: "138px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Conscious Interference
              </Typography>

              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "4px 0px 16px 0px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "21px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "0px ",
                      sm: "0px ",
                      md: "0px ",
                      lg: "25px ",
                      xl: "0px ",
                    },
                  }}
                >
                  Next you'll learn the habit of tracking each drink as soon as
                  you finish it, which creates a mindful pause before you start
                  the next one.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* yellow card three */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 16px 16px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={about_circle}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "138px",
                  height: "138px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Positivity
              </Typography>
              {/* </Grid> */}

              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "4px 0px 16px 0px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "21px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "0px ",
                      sm: "0px ",
                      md: "0px ",
                      lg: "25px ",
                      xl: "0px ",
                    },
                  }}
                >
                  We know this is a big step that can be tough at times. That's
                  why we offer coaching through SMS and email to give you
                  support, advice, and motivation.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* 5th container */}

      {/* <Grid
        container
        xs={12}
        lg={12}
        sx={{
          bgcolor: "blue",
          display: "flex",
          padding: {
            xs: "0px 0px 0px 0px",
            sm: "0px 0px 0px 0px",
            md: "0px 0px 0px 0px",
            lg: "80px 88px 88px 80px",
            xl: "0px 0px 0px 0px",
          },
        }}
      >
        <Grid
          item
          xs={12}
          lg={3}
          sx={{ padding: "0px 0px 0px 0px", bgcolor: "yellow" }}
        >
          <Typography
            sx={{
              justifyContent: "flex-start",
              alighnItems: "center",

              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "100px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "24px",
                xl: "24px",
              },
              fontWeight: "400",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              textAlign: "center",
              lineHeight: {
                xs: "0px ",
                sm: "0px ",
                md: "0px ",
                lg: "30px ",
                xl: "0px ",
              },
            }}
          >
            “We started this company because we believe there's power in finding
            balance. Science tells us that becoming aware of our habits, and
            having a partner who can nudge us in the right direction, can make a
            huge difference.”
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          sx={{ padding: "0px 0px 0px 0px", bgcolor: "yellow" }}
        >
          <img
            src={cont5}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "586px",
              // maxheight: "586px",
              height: "auto",
              alighnItems: "left",
            }}
          />
        </Grid>
        {/* </Grid> */}
      {/* </Grid>  */}

      <Grid
        container
        xs={12}
        md={10}
        lg={12}
        sx={{
          display: "flex",
          padding: {
            xs: "0px 0px 0px 0px",
            sm: "0px 0px 0px 0px",
            md: "0px 0px 80px 88px",
            lg: "80px 88px 80px 88px",
            xl: "0px 0px 80px 88px",
          },
        }}
      >
        <Grid
          container
          item
          xs={12}
          md={5}
          lg={6}
          sx={{ justifyContent: "center", alighnItems: "center" }}
        >
          <Grid item xs={12} md={5} lg={8}>
            <Typography
              sx={{
                justifyContent: "flex-start",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "90px 0px 0px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "25px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                // textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "27px ",
                  xl: "0px ",
                },
              }}
            >
              “We started this company because we believe there's power in
              finding balance. Science tells us that becoming aware of our
              habits, and having a partner who can nudge us in the right
              direction, can make a huge difference.”
            </Typography>

            <Typography
              sx={{
                justifyContent: "flex-start",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "20px 0px 0px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "900",
                fontFamily: "Recoleta medium",
                color: "#000000",
                // textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "27px ",
                  xl: "0px ",
                },
              }}
            >
              —
            </Typography>

            <Typography
              sx={{
                justifyContent: "flex-start",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "10px 0px 0px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "23px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "",
                color: "#000000",
                // textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "27px ",
                  xl: "0px ",
                },
              }}
            >
              Nick & Ian
            </Typography>

            <Typography
              sx={{
                justifyContent: "flex-start",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "0px 0px 0px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "400",
                fontFamily: "Recoleta medium",
                color: "#000000",
                // textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "27px ",
                  xl: "0px ",
                },
              }}
            >
              Sunnyside Founders
            </Typography>

            <Button
              // onClick={functionopenpopup}
              sx={{
                marginTop: "20px",
                width: {
                  xs: "100%",
                  sm: "auto",
                  md: "80%",
                  lg: "90%",
                  xl: "50%",
                },
                maxWidth: "550px",
                height: "50px",
                bgcolor: "#5DC0FE",
                alignItems: "center",
                color: "white",
                "&:hover": {
                  bgcolor: "#5EC0FF", // Background color on hover
                  color: "white", // Text color on hover
                },
                fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "18px" }, // Responsive font size
                fontWeight: "700",
                fontFamily: "poppins",
              }}
            >
              Take a Survey
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <img
            src={cont5}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "auto",
              height: "auto",
            }}
          />
        </Grid>
      </Grid>

      {/* 6th Container  */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alighnItems: "center",
          bgcolor: "#FBF7E9",
          padding: {
            xs: "0px 0px 0px 0px",
            sm: "0px 0px 0px 0px",
            md: "0px 0px 0px 0px",
            lg: "80px 88px 80px 88px",
            xl: "0px 0px 0px 0px",
          },
        }}
      >
        <Grid item xs={6} md={12} lg={12}>
          <img
            src={about1}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "42px",
              height: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Typography
            sx={{
              justifyContent: "center",
              alighnItems: "center",

              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "1px 0px 10px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "48px",
                xl: "24px",
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              //   textAlign: "left",
            }}
          >
            In the News
          </Typography>
        </Grid>
        {/* 
        <Grid
          item
          xs={12}
          md={12}
          lg={7.3}
          sx={{
            justifyContent: "center",
            alighnItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              justifyContent: "center",
              alighnItems: "center",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "right",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "1px 0px 40px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "25px",
                  xl: "24px",
                },
                fontWeight: "200",
                fontFamily: "Recoleta medium",
                color: "#000000",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "35px ",
                  xl: "0px ",
                },
              }}
            >
              Sunnyside helps you reach your goals—and stick with them long
              term—by focusing on three key, scientifically-proven superpowers:
            </Typography>
          </Grid> 
        </Grid> */}

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          {/* yellow card 3 */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 16px 16px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={thenewyorktimes}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "282px",
                  height: "auto",
                  alighnItems: "left",
                }}
              />
            </Grid>

            {/* <Grid item xs={12} md={12} lg={12}> */}
            {/* <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Pre-commitment
              </Typography> */}
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Excessive Drinking Rose During the Pandemic. Here Are Ways to
                Cut Back.
              </Typography>
            </Grid>
            {/* </Grid> */}
          </Grid>
          {/* yellow card two */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 16px 16px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={usatoday}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "359px",
                  height: "auto",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              {/* <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Conscious Interference
              </Typography> */}

              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "4px 0px 16px 0px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "28px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "0px ",
                      sm: "0px ",
                      md: "0px ",
                      lg: "30px ",
                      xl: "0px ",
                    },
                  }}
                >
                  Sober living for everyone is rising in popularity as Chrissy
                  Teigen, more ditch drinking.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* yellow card three */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF8E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 16px 16px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={forbes}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "216px",
                  height: "55px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              {/* <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "28px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Positivity
              </Typography> */}
              {/* </Grid> */}

              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "4px 0px 16px 0px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "28px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "0px ",
                      sm: "0px ",
                      md: "0px ",
                      lg: "30px ",
                      xl: "0px ",
                    },
                  }}
                >
                  Six Creative Takes on Dry January.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* 7th container */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alighnItems: "center",

          padding: {
            xs: "0px 0px 0px 0px",
            sm: "0px 0px 0px 0px",
            md: "0px 0px 0px 0px",
            lg: "80px 88px 80px 88px",
            xl: "0px 0px 0px 0px",
          },
        }}
      >
        <Grid item xs={6} md={12} lg={12}>
          <img
            src={daydrink}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "42px",
              height: "auto",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={8}
          sx={{
            justifyContent: "center",
            alighnItems: "center",
          }}
        >
          <Typography
            sx={{
              justifyContent: "center",
              alighnItems: "center",

              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "8px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "48px",
                xl: "24px",
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              textAlign: "center",
              lineHeight: {
                xs: "0px ",
                sm: "0px ",
                md: "0px ",
                lg: "45px ",
                xl: "0px ",
              },
            }}
          >
            From the Blog
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "64px 0px 0px 0px",
              xl: "0px 0px 0px 0px",
            },
            // justifyContent: "space-evenly",
            display: "flex",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={2.5}
            sx={{
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 16px 0px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item sx={{}}>
              <img
                src={daydrink}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "202px",
                  height: "auto",
                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "0px 16px 0px 16px",
                    xl: "0px 0px 0px 0px",
                  },
                }}
              />
            </Grid>

            <Typography
              sx={{
                // justifyContent: "center",
                // alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "20px 0px 20px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "28px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124a2c",
                textAlign: "left",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "35px ",
                  xl: "0px ",
                },
              }}
            >
              10 Tips to Day Drink Mindfully This Summer
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={2.5}
            sx={{
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 16px 0px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item sx={{}}>
              <img
                src={daydrink}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "202px",
                  height: "auto",
                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "0px 16px 0px 16px",
                    xl: "0px 0px 0px 0px",
                  },
                }}
              />
            </Grid>

            <Typography
              sx={{
                // justifyContent: "center",
                // alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "20px 0px 20px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "28px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124a2c",
                textAlign: "left",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "35px ",
                  xl: "0px ",
                },
              }}
            >
              20+ Questions Answered About Mixing ADHD And Alcohol
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={2.5}
            sx={{
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 16px 0px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item sx={{}}>
              <img
                src={daydrink}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "202px",
                  height: "auto",
                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "0px 16px 0px 16px",
                    xl: "0px 0px 0px 0px",
                  },
                }}
              />
            </Grid>

            <Typography
              sx={{
                // justifyContent: "center",
                // alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "20px 0px 20px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "28px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124a2c",
                textAlign: "left",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "35px ",
                  xl: "0px ",
                },
              }}
            >
              11 Health Benefits of a 30-Day No Alcohol Reset Challenge
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={2.5}
            sx={{
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 16px 0px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item sx={{}}>
              <img
                src={daydrink}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "202px",
                  height: "auto",
                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "0px 16px 0px 16px",
                    xl: "0px 0px 0px 0px",
                  },
                }}
              />
            </Grid>

            <Typography
              sx={{
                // justifyContent: "center",
                // alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "20px 0px 20px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "28px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124a2c",
                textAlign: "left",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "35px ",
                  xl: "0px ",
                },
              }}
            >
              Keep Your Kidneys in Shape With These Mindful Drinking Tips
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={2.5}
            sx={{
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 16px 0px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item sx={{}}>
              <img
                src={daydrink}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "202px",
                  height: "auto",
                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "0px 16px 0px 16px",
                    xl: "0px 0px 0px 0px",
                  },
                }}
              />
            </Grid>

            <Typography
              sx={{
                // justifyContent: "center",
                // alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "20px 0px 20px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "28px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124a2c",
                textAlign: "left",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "35px ",
                  xl: "0px ",
                },
              }}
            >
              16 Psychology-Backed Tips to Change Your Drinking Habits{" "}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* 8th container */}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alighnItems: "center",
          bgcolor: "#FBF7E9",
          padding: {
            xs: "0px 0px 0px 0px",
            sm: "0px 0px 0px 0px",
            md: "0px 0px 0px 0px",
            lg: "80px 88px 80px 88px",
            xl: "0px 0px 0px 0px",
          },
        }}
      >
        <Grid item xs={6} md={12} lg={12}>
          <img
            src={about1}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "42px",
              height: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Typography
            sx={{
              justifyContent: "center",
              alighnItems: "center",

              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "1px 0px 10px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "48px",
                xl: "24px",
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              //   textAlign: "left",
            }}
          >
            Meet Our Team
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={7.3}
          sx={{
            justifyContent: "center",
            alighnItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              justifyContent: "center",
              alighnItems: "center",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },
            }}
          ></Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          {/* yellow card 3 */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 16px 16px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={about_circle}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "200px",
                  height: "200px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 1px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "24px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Nick Allen
              </Typography>
              {/* </Grid> */}

              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "4px 0px 16px 12px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "20px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "0px ",
                      sm: "0px ",
                      md: "0px ",
                      lg: "25px ",
                      xl: "0px ",
                    },
                  }}
                >
                  CEO & Founder
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* card 2 */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 16px 16px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={about_circle}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "200px",
                  height: "200px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 1px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "24px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Nick Allen
              </Typography>
              {/* </Grid> */}

              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "4px 0px 16px 12px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "20px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "0px ",
                      sm: "0px ",
                      md: "0px ",
                      lg: "25px ",
                      xl: "0px ",
                    },
                  }}
                >
                  CEO & Founder
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* card 3  */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#FBF7E9",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 16px 16px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={about_circle}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "200px",
                  height: "200px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 1px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "24px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Nick Allen
              </Typography>
              {/* </Grid> */}

              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "4px 0px 16px 12px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "20px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "0px ",
                      sm: "0px ",
                      md: "0px ",
                      lg: "25px ",
                      xl: "0px ",
                    },
                  }}
                >
                  CEO & Founder
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* card 5 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* card 6 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* card 7 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*  card 8*/}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* card 9 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*  card 10*/}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* card 11 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* card 12 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*  card 13 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* card 14 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* card 15 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* card 16 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#FBF7E9",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* 9th container */}
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifyContent: "center",
          alighnItems: "center",
          bgcolor: "#ffffff",
          padding: {
            xs: "0px 0px 0px 0px",
            sm: "0px 0px 0px 0px",
            md: "0px 0px 0px 0px",
            lg: "80px 88px 80px 88px",
            xl: "0px 0px 0px 0px",
          },
        }}
      >
        {/* <Grid item xs={6} md={12} lg={12}>
          <img
            src={about1}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "42px",
              height: "auto",
            }}
          />
        </Grid> */}

        <Grid item xs={12} md={12} lg={12}>
          <Typography
            sx={{
              justifyContent: "center",
              alighnItems: "center",

              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "1px 0px 10px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "48px",
                xl: "24px",
              },
              fontWeight: "400",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              //   textAlign: "left",
            }}
          >
            Advisors
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={7.3}
          sx={{
            justifyContent: "center",
            alighnItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              justifyContent: "center",
              alighnItems: "center",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 0px",
                xl: "0px 0px 0px 0px",
              },
            }}
          ></Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            bgcolor: "#ffffff",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          {/* yellow card 3 */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#ffffff",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 16px 16px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={about_circle}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "200px",
                  height: "200px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 1px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "24px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Nick Allen
              </Typography>
              {/* </Grid> */}

              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "4px 0px 16px 12px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "20px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "0px ",
                      sm: "0px ",
                      md: "0px ",
                      lg: "25px ",
                      xl: "0px ",
                    },
                  }}
                >
                  CEO & Founder
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* card 2 */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#ffffff",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 16px 16px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={about_circle}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "200px",
                  height: "200px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 1px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "24px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Nick Allen
              </Typography>
              {/* </Grid> */}

              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "4px 0px 16px 12px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "20px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "0px ",
                      sm: "0px ",
                      md: "0px ",
                      lg: "25px ",
                      xl: "0px ",
                    },
                  }}
                >
                  CEO & Founder
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* card 3  */}
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sx={{
              bgcolor: "#ffffff",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "16px 16px 16px 16px",
                xl: "0px 0px 0px 0px",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img
                src={about_circle}
                alt="logo"
                style={{
                  width: "100%", // Make image responsive
                  maxWidth: "200px",
                  height: "200px",
                  alighnItems: "left",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 1px 0px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "24px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  fontFamily: "Recoleta medium",
                  color: "#124A2C",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "30px ",
                    xl: "0px ",
                  },
                }}
              >
                Nick Allen
              </Typography>
              {/* </Grid> */}

              <Grid item xs={12} md={12} lg={11.5}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    alighnItems: "center",

                    padding: {
                      xs: "0px 0px 0px 0px",
                      sm: "0px 0px 0px 0px",
                      md: "0px 0px 0px 0px",
                      lg: "4px 0px 16px 12px",
                      xl: "0px 0px 0px 0px",
                    },
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      lg: "20px",
                      xl: "24px",
                    },
                    fontWeight: "400",
                    fontFamily: "Recoleta medium",
                    color: "#000000",
                    textAlign: "center",
                    lineHeight: {
                      xs: "0px ",
                      sm: "0px ",
                      md: "0px ",
                      lg: "25px ",
                      xl: "0px ",
                    },
                  }}
                >
                  CEO & Founder
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#ffffff",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* card 5 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#ffffff",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* card 6 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#ffffff",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* card 7 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#ffffff",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*  card 8*/}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#ffffff",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* card 9 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#ffffff",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*  card 10*/}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#ffffff",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* card 11 */}
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            bgcolor: "#ffffff",
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "16px 16px 16px 16px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <img
              src={about_circle}
              alt="logo"
              style={{
                width: "100%", // Make image responsive
                maxWidth: "200px",
                height: "200px",
                alighnItems: "left",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                justifyContent: "center",
                alighnItems: "center",

                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px 0px 0px",
                  md: "0px 0px 0px 0px",
                  lg: "4px 0px 1px 0px",
                  xl: "0px 0px 0px 0px",
                },
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                },
                fontWeight: "600",
                fontFamily: "Recoleta medium",
                color: "#124A2C",
                textAlign: "center",
                lineHeight: {
                  xs: "0px ",
                  sm: "0px ",
                  md: "0px ",
                  lg: "30px ",
                  xl: "0px ",
                },
              }}
            >
              Nick Allen
            </Typography>
            {/* </Grid> */}

            <Grid item xs={12} md={12} lg={11.5}>
              <Typography
                sx={{
                  justifyContent: "center",
                  alighnItems: "center",

                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "0px 0px 0px 0px",
                    md: "0px 0px 0px 0px",
                    lg: "4px 0px 16px 12px",
                    xl: "0px 0px 0px 0px",
                  },
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "20px",
                    xl: "24px",
                  },
                  fontWeight: "400",
                  fontFamily: "Recoleta medium",
                  color: "#000000",
                  textAlign: "center",
                  lineHeight: {
                    xs: "0px ",
                    sm: "0px ",
                    md: "0px ",
                    lg: "25px ",
                    xl: "0px ",
                  },
                }}
              >
                CEO & Founder
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* 10th container */}
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          bgcolor: "#ffe635",
          display: "flex",
          padding: {
            xs: "0px 0px 0px 0px",
            sm: "0px 0px 0px 0px",
            md: "0px 0px 80px 88px",
            lg: "0px 88px 0px 88px",
            xl: "0px 0px 0px 0px",
          },
        }}
      >
        {/* <Grid
          item
          xs={12}
          md={12}
          lg={6.5}
          sx={{
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 80px 88px",
              lg: "0px 0px 0px 0px",
              xl: "0px 0px 0px 0px",
            },
            justifyContent: "flex-end",
            alighnItems: "flex-end",
          }}
        >
          <img
            src={mobile}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "478px",
              height: "auto",
            }}
          />
        </Grid> */}

        <Grid
          item
          xs={12}
          md={12}
          lg={6.5}
          sx={{
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 80px 0px",
              lg: "0px 0px 0px 0px",
              xl: "0px 0px 0px 0px",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center", // Corrected typo
            height: "100%", // Make Grid full height if needed
          }}
        >
          <img
            src={mobile}
            alt="logo"
            style={{
              width: "100%", // Make image responsive
              maxWidth: "478px",
              height: "auto",
            }}
          />
        </Grid>

        {/* <Grid
          item
          xs={12}
          md={5}
          lg={5.5}
          sx={{
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "px 0px 80px 0px",
              xl: "0px 0px 0px 0px",
            },
          }}
        >
          <Typography
            sx={{
              justifyContent: "center",
              alignItems: "center",

              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "px 0px px 0px",
                xl: "0px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "48px",
                xl: "24px",
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              textAlign: "left",
              lineHeight: {
                xs: "0px ",
                sm: "0px ",
                md: "0px ",
                lg: "54px ",
                xl: "0px ",
              },
            }}
          >
            Start building healthier drinking habits today.
          </Typography>
          <Button
            // onClick={functionopenpopup}
            sx={{
              marginTop: "20px",
              width: {
                xs: "100%",
                sm: "auto",
                md: "80%",
                lg: "90%",
                xl: "50%",
              },
              maxWidth: "550px",
              height: "50px",
              bgcolor: "#5DC0FE",
              alignItems: "center",
              color: "white",
              "&:hover": {
                bgcolor: "#5EC0FF", // Background color on hover
                color: "white", // Text color on hover
              },
              fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "18px" }, // Responsive font size
              fontWeight: "700",
              fontFamily: "poppins",
            }}
          >
            Take a Survey
          </Button>
        </Grid> */}
        <Grid
          item
          xs={12}
          md={5}
          lg={5.5}
          sx={{
            padding: {
              xs: "0px 0px 0px 0px",
              sm: "0px 0px 0px 0px",
              md: "0px 0px 0px 0px",
              lg: "140px 0px 80px 0px", // fixed padding typo
              xl: "0px 0px 0px 0px",
            },
            display: "flex",
            flexDirection: "column", // Stack elements vertically
            justifyContent: "center", // Vertically center the content
            alignItems: "left", // Horizontally center the content
            height: "100%", // Ensure it takes full height
          }}
        >
          <Typography
            sx={{
              justifyContent: "left",
              alignItems: "left",
              padding: {
                xs: "0px 0px 0px 0px",
                sm: "0px 0px 0px 0px",
                md: "0px 0px 0px 0px",
                lg: "0px 0px 0px 0px", // fixed padding typo
                xl: "0px 0px 0px 0px",
              },
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "48px",
                xl: "24px",
              },
              fontWeight: "600",
              fontFamily: "Recoleta medium",
              color: "#124A2C",
              textAlign: "left", // left text
              lineHeight: {
                xs: "28px",
                sm: "28px",
                md: "28px",
                lg: "54px",
                xl: "28px",
              },
            }}
          >
            Start building healthier drinking habits today.
          </Typography>
          <Button
            // onClick={functionopenpopup}
            sx={{
              justifyContent: "center",
              alignItems: "left",
              marginTop: "45px",
              width: {
                xs: "100%",
                sm: "auto",
                md: "80%",
                lg: "69%",
                xl: "50%",
              },
              maxWidth: "550px",
              height: "55px",
              bgcolor: "#5DC0FE",
              color: "white",
              "&:hover": {
                bgcolor: "#5EC0FF", // Background color on hover
                color: "white", // Text color on hover
              },
              fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "18px" }, // Responsive font size
              fontWeight: "700",
              fontFamily: "poppins",
            }}
          >
            Take a Survey
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default About;
