// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alf-box {
    border: 1px solid #ddd;
    background-color: #f9f9f9;
  }
  
  .alf-info p {
    font-size: 16px;
    color: #333;
  }
  
  .alf-footer {
    margin-top: 20px;
    padding: 20px 0;
  }
  
  .btn-group-justified {
    display: flex;
    justify-content: space-between;
  }
  
  .btn-group-justified .btn {
    flex: 1 1;
    margin-right: 10px;
  }
  
  .btn-group-justified .btn:last-child {
    margin-right: 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/style/AlfDetails.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,yBAAyB;EAC3B;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,SAAO;IACP,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".alf-box {\n    border: 1px solid #ddd;\n    background-color: #f9f9f9;\n  }\n  \n  .alf-info p {\n    font-size: 16px;\n    color: #333;\n  }\n  \n  .alf-footer {\n    margin-top: 20px;\n    padding: 20px 0;\n  }\n  \n  .btn-group-justified {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .btn-group-justified .btn {\n    flex: 1;\n    margin-right: 10px;\n  }\n  \n  .btn-group-justified .btn:last-child {\n    margin-right: 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
